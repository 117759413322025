import {
  add,
  isValid,
  parseISO,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfSecond,
  startOfYear
} from "date-fns";
const dateUnits = ["s", "m", "h", "d", "M", "y"];
const dateUnitMap = {
  y: "years",
  M: "months",
  d: "days",
  h: "hours",
  m: "minutes",
  s: "seconds"
};
const startOf = {
  y: startOfYear,
  M: startOfMonth,
  d: startOfDay,
  h: startOfHour,
  m: startOfMinute,
  s: startOfSecond
};
const relativeDate = /^\$now(?<units>\(((y|M|d|h|m|s):(-?\d+),?)+\))?$/;
const relativeUnit = /(y|M|d|h|m|s):(-?\d+)/g;
const absoluteDate = /^(?<y>\d{4,})(-(?<M>\d\d)(-(?<d>\d\d)([T ](?<h>\d\d)(:(?<m>\d\d)(:(?<s>\d\d))?)?)?)?)?/;
function parseDate(value, now = /* @__PURE__ */ new Date()) {
  let date;
  let units;
  const relativeMatch = relativeDate.exec(value);
  if (relativeMatch) {
    date = now;
    const unitsGroup = relativeMatch.groups?.units;
    if (unitsGroup) {
      const unitMatches = unitsGroup.matchAll(
        relativeUnit
      );
      units = [];
      for (const [_, unit, delta] of unitMatches) {
        date = add(date, { [dateUnitMap[unit]]: Number(delta) });
        units.push(unit);
      }
    } else {
      units = ["d"];
    }
  } else {
    date = parseISO(value);
    units = Object.entries(absoluteDate.exec(value)?.groups || {}).filter(([_, n]) => !!n).map(([unit]) => unit);
  }
  const minUnit = getMinDateUnit(units) || "s";
  return [startOf[minUnit](date), minUnit];
}
function getMinDateUnit(units) {
  return dateUnits.find((unit) => units.includes(unit));
}
function isValidDate(s) {
  if (typeof s === "string") {
    return relativeDate.test(s) || isValid(parseISO(s));
  }
  return isValid(s);
}
function isDateBetween(date, start, end) {
  let valid = isValidDate(date);
  if (start) {
    start = typeof start === "string" ? parseISO(start) : start;
    valid = valid && date >= start;
  }
  if (end) {
    end = typeof end === "string" ? parseISO(end) : end;
    valid = valid && date <= end;
  }
  return valid;
}
export {
  dateUnitMap,
  dateUnits,
  getMinDateUnit,
  isDateBetween,
  isValidDate,
  parseDate
};
